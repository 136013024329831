import React, { useCallback, useEffect, useMemo } from 'react'
import { hooks } from '@front/volcanion'

import { Loader } from '@front/squirtle'

import Callbacks from './callbacks'
import Memos from './memos'
import PopupCallbacks from './Popup/callbacks'
import { HookUtils } from '@front/volcanion/utils/'
import OnKeyDown from './onKeyDown'

const withContainer = Component => props => {
  const { setIsWaiting, isWaiting } = props

  const isReady = hooks.useFormStatus('isReady')
  const isMounted = hooks.useFormStatus('isMounted')

  const setFormValues = hooks.useFormChange()
  const navigate = hooks.useNavigate()
  const [dialogs, { openDialog }] = hooks.useDialogs()
  const { openNotification, closeNotification } = hooks.useNotification()
  const reset = hooks.useFormReset()
  const [meta] = hooks.useFormMeta()
  const [operator] = hooks.useActiveUser({ populate: ['auth', 'info'] })
  const requestSubmit = hooks.useFormFunction('requestSubmit')

  const { EXTERNAL_PHONE_SERVICE } = hooks.useRelayConstants(['EXTERNAL_PHONE_SERVICE'])
  const B2B_ENABLED = hooks.useRelayFeature('B2B_ENABLED')

  const [{
    record_id,
    isManagingRecord,
    isB2B,
    isGrouped,
    isManagingBillingRecord,
    isMapOpen,
    isReadOnly,
    displayDownloadBillingButton,
    user_id,
    confirmed
  }, setFormState] = hooks.useFormState()

  const [
    mode,
    ridemode_duration,
    gsm,
    commercial_package,
    companycontract,
    companyservice_id,
    schedule_type,
    destination,
    estimation_type,
  ] = hooks.useFieldValues(['ridemode', 'ridemode_duration', 'user.gsm', 'commercial_package', 'user.companycontract', 'user.companyservice', 'schedule_type', 'destination', 'estimation_type'])

  const [order] = hooks.useModel('order', [record_id], { single: true, populate: ['assigned_transport.driver.info', 'assigned_transport.driver.auth', 'info', 'do.auth', 'loads'] })
  const [ridemode] = hooks.useModel('ridemode', [mode], { single: true, populate: ['config'] })
  const [companyservice] = hooks.useModel('companyservice', [companyservice_id], { single: true })

  const [exclude_heatmap] = hooks.useExcludeHeatMap({ user_id, companyservice_id })
  const estimation_status = hooks.useEstimationStatus()
  const [selectedEstimation] = hooks.useSelectedEstimation(commercial_package, estimation_type)
  const [eventGenerate] = hooks.useModelFunction('event', 'generate')
  const [billingAction] = hooks.useModelFunction('order', 'billingAction')
  const [cancel] = hooks.useModelFunction('order', 'cancel')
  const [downloadInvoice] = hooks.useOrderInvoice(record_id)
  const [isDownloadAvailable] = hooks.useModelFunction('order', 'isDownloadAvailable')
  const authorization = hooks.useCurrentAuthorization()

  const canCreate = authorization?.create
  const canModify = authorization?.modify
  const canManualDispatch = !!confirmed
  const { display: canSendMessage } = hooks.usePageAuthorization('/message/send')
  const { display: canDisplayIncidents } = hooks.usePageAuthorization('/incident')
  const { display: canDisplayDispatch } = hooks.usePageAuthorization('/dispatch')
  const { display: canDisplayDriver } = hooks.usePageAuthorization('/driver')

  const createdAt = order?.createdAt
  const driver_number = order?.assigned_transport?.driver?.auth?.gsm
  const driver_id = order?.assigned_transport?.driver?.user_id
  const driver_client_id = order?.assigned_transport?.driver?.info?.user_client_id
  const driver_full_name = order?.assigned_transport?.driver?.info?.full_name
  const status = order?.status
  const canCancel = !_.includes(['complete', 'canceled'], status) && canModify
  const customer_number = order?.loads[0]?.src_phone || order?.do?.auth?.gsm
  const call_id = order?.info?.call_id
  const destination_enabled = !!(isManagingRecord && isReadOnly) ? !!destination : ridemode?.config?.display_destination_field
  const duration_enabled = !!(isManagingRecord && isReadOnly) ? !!ridemode_duration : !!ridemode?.duration_required && !ridemode?.config?.display_destination_field
  const focusedField = _.find(meta, ['active', true])
  const displayOpenSimulatorButton = _.includes(['started', 'at_src', 'on_board', 'in_route', 'at_dst', 'off_board'], order?.assigned_transport?.status)

  const refuse = useCallback(() => billingAction({ order_id: record_id, action: 'refuse' }), [record_id])
  const approve = useCallback(() => billingAction({ order_id: record_id, action: 'approve' }), [record_id])
  const handleChangeIsB2B = useCallback(() => !!B2B_ENABLED && setFormState({ isB2B: !isB2B }), [B2B_ENABLED, isB2B, setFormState])

  const handleOpenCancelConfirmationDialog = useCallback(PopupCallbacks.handleOpenCancelConfirmationDialogHandler(
    openDialog,
    record_id,
    status,
    driver_client_id,
    driver_full_name
  ), [openDialog, record_id, status, driver_client_id, driver_full_name])
  const handleInvoiceDownload = useCallback(Callbacks.handleInvoiceDownloadHanlder(downloadInvoice, openNotification), [downloadInvoice, openNotification])
  const isDownloadAvailableStatus = useCallback(Callbacks.isDownloadAvailableStatusHandler(isDownloadAvailable, setFormState), [isDownloadAvailable, setFormState])
  const handleCancel = useCallback(Callbacks.handleCancelHandler(cancel, order, openNotification, handleOpenCancelConfirmationDialog, isGrouped), [cancel, order, openNotification, handleOpenCancelConfirmationDialog, isGrouped])
  const handleClickCallCenter = useCallback(Callbacks.handleClickCallCenterHandler(reset, setIsWaiting), [reset, setIsWaiting])
  const handleClickMap = useCallback(Callbacks.handleClickMapHandler(isMapOpen, setFormState), [isMapOpen, setFormState])
  const handleNewOrder = useCallback(Callbacks.handleNewOrderHandler(navigate), [navigate])
  const handleValidateNotification = useCallback(Callbacks.handleValidateNotificationHandler(openNotification, closeNotification, setFormValues), [openNotification, closeNotification, setFormValues])

  const canOrangeWait = useMemo(() => Memos.handleCanOrangeWait(schedule_type, createdAt, call_id, EXTERNAL_PHONE_SERVICE), [schedule_type, createdAt, call_id, EXTERNAL_PHONE_SERVICE])
  const handleWaitingOrange = useCallback(Callbacks.handleWaitingOrangeHandler(), [])
  const handleOrangeCall = useCallback(Callbacks.handleOrangeCallHandler(), [])
  const handleCall = useCallback(Callbacks.handleCallHandler(EXTERNAL_PHONE_SERVICE, handleOrangeCall), [EXTERNAL_PHONE_SERVICE, handleOrangeCall])
  const handleOpenSendCommentDialog = useCallback(PopupCallbacks.handleOpenSendCommentDialogHandler(openDialog), [openDialog])
  const handleOpenManualDispatch = useCallback(
    PopupCallbacks.handleOpenManualDispatchHandler(openDialog, order, canManualDispatch, handleCall),
    [openDialog, order, canManualDispatch, handleCall]
  )
  const handleOpenSendMessageDriver = useCallback(PopupCallbacks.handleOpenSendMessageDriverHandler(openDialog), [openDialog])
  const handleOpenRedispatch = useCallback(PopupCallbacks.handleOpenRedispatchHandler(openDialog, status, driver_client_id, driver_full_name),
    [openDialog, status, driver_client_id, driver_full_name])
  const handleOpenClaimRedispatch = useCallback(PopupCallbacks.handleOpenClaimRedispatchHandler(openDialog, eventGenerate, order), [openDialog, eventGenerate, order])
  const handleOpenShortcut = useCallback(PopupCallbacks.handleOpenShortcutHandler(openDialog), [openDialog])
  const handleOpenSimulator = useCallback(PopupCallbacks.handleOpenSimulatorHandler(openDialog), [openDialog])
  const handleOrderCopy = useCallback(Callbacks.handleOrderCopyHandler(record_id), [record_id])

  const keyDownState = {
    isReadOnly,
    isMapOpen,
    isManagingBillingRecord,
    isManagingRecord,
    canOrangeWait,
    driver_number,
    customer_number,
    gsm,
    operator,
    focusedField,
    companycontract,
    companyservice,
    canCreate,
    canModify,
    canSendMessage,
    canDisplayIncidents,
    canDisplayDispatch,
    canDisplayDriver
  }
  const keyDownFunctions = {
    refuse,
    approve,
    handleOpenShortcut,
    handleCancel,
    handleCall,
    handleWaitingOrange,
    handleOpenSendCommentDialog,
    handleOpenManualDispatch,
    handleOpenSendMessageDriver,
    handleOpenRedispatch,
    handleOpenClaimRedispatch,
    handleClickMap,
    handleChangeIsB2B,
    navigate,
    openNotification,
    setFormValues,
    setFormState,
    requestSubmit
  }

  const ctrlKeyMap = useCallback(OnKeyDown.ctrlKeyMapHandler(order, keyDownState, keyDownFunctions), [order, keyDownState, keyDownFunctions])
  const altKeyMap = useCallback(OnKeyDown.altKeyMapHandler(order, keyDownState, keyDownFunctions), [order, keyDownState, keyDownFunctions])
  const baseKeyMap = useCallback(OnKeyDown.baseKeyMapHandler(order, keyDownState, keyDownFunctions), [order, keyDownState, keyDownFunctions])
  const onKeyDown = useCallback(OnKeyDown.onKeyDownHandler(dialogs, ctrlKeyMap, altKeyMap, baseKeyMap), [dialogs, ctrlKeyMap, altKeyMap, baseKeyMap])

  const isEstimationLoading = HookUtils.getLoadingState([estimation_status])

  useEffect(() => {
    !isEstimationLoading && handleValidateNotification(selectedEstimation?.validation?.errors)
  }, [isEstimationLoading])

  useEffect(() => {
    if (!isWaiting && !!isReady) {
      setFormValues([{ field: 'user.gsm', meta: { focus: true } }])
    }
  }, [isWaiting])

  useEffect(() => {
    if (!!isMounted && !isManagingRecord) {
      setFormValues([{ field: 'exclude_heatmap', value: exclude_heatmap }])
    }
  }, [exclude_heatmap])

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown)
    return () => {
      window.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])

  useEffect(() => {
    if (!!isManagingRecord)
      isDownloadAvailableStatus(record_id)

  }, [isManagingRecord])

  const disabledSubmitButton = !!HookUtils.getLoadingState(estimation_status)

  const mergedProps = {
    disabledSubmitButton,
    destination_enabled,
    duration_enabled,
    commercial_package,
    driver_id,
    record_id,
    canOrangeWait,
    canCancel,
    isManagingRecord,
    isReadOnly,
    isEstimationLoading: estimation_status?.isLoading,
    displayDownloadBillingButton,
    displayOpenSimulatorButton,
    handleClickMap,
    handleClickCallCenter,
    handleWaitingOrange,
    handleOpenSendCommentDialog,
    handleOpenManualDispatch,
    handleOpenSendMessageDriver,
    handleOpenRedispatch,
    handleOpenClaimRedispatch,
    handleNewOrder,
    handleCancel,
    handleInvoiceDownload,
    handleOpenSimulator,
    handleOrderCopy,
    canCreate,
    canModify,
    canSendMessage,
    canManualDispatch
  }

  return (
    <Loader isLoading={!isReady}>
      <Component {...mergedProps} {...props} />
    </Loader>
  )
}

export default withContainer
