import React from 'react'
import GeoUtils from "@front/volcanion/utils/geo"
import { I18n } from '@front/volcanion'
import FormatUtils from '@front/squirtle/utils/format'
import TimeUtils from '@front/volcanion/utils/time'
import errors from '@front/volcanion/classes/error'
import _ from "lodash"
import moment from 'moment'

import { OrangeContact } from '../..'

import { Button } from "@mui/material"

const getAddressInfo = (values, prefix, save_comment) => {
  const prefix_field = prefix === 'source' ? 'src' : 'dest'
  if (prefix === 'destination' && !!_.has(values, prefix) && !_.get(values, prefix))
    return null
  else if (!_.get(values, prefix)) return undefined
  else {
    return _.merge(
      {}, {
      ..._.pick(_.get(values, prefix), _.flatten([GeoUtils.getAddressKeys(), ['name', 'owner']])),
      comment: !!save_comment ? _.get(values, `info.${prefix_field}_comment`) : undefined,
    })
  }
}

const verifyData = (isValid, value, nullValue = undefined) => !!isValid ? value : nullValue

const getReservationInfo = (reservation_info, reservationinfo_id, order_reservationinfo_id) => {
  if (!_.isEmpty(reservation_info)) {
    if (!!reservationinfo_id)
      return [_.merge({ reservationinfo: reservationinfo_id, order_reservationinfo_id }, reservation_info)]
    return []
  } else if (!reservationinfo_id) {
    return []
  }
  return undefined
}

const getUser = (formValues, formState) => {
  if (!!formState?.isB2B)
    return formState?.selected_user
  else if (!!formState?.user_id)
    return formState?.user_id
  else {
    return {
      auth: {
        gsm: formValues?.user?.gsm,
        application: formValues?.user?.application,
        user_type: 'booker'
      },
      customerinfo: {
        name: formValues?.user?.name,
        client_number: formValues?.user?.client_number,
      },
      info: {
        preferred_language: 'fr',
        first_name: formValues?.user?.first_name,
        last_name: formValues?.user?.last_name
      }
    }
  }
}

class Callbacks {
  static getEmptyFormHandler() {
    return function getEmptyForm() {
      return {
        schedule_type: 'immediate',
        vehicle_count: 1,
        load_count: 1,
        estimation_type: 'estimated',
        exclude_heatmap: false,
        load_type: 'user',
      }
    }
  }
  static getEmptyStateHandler(domain) {
    return function getEmptyState() {
      return {
        isB2B: false,
        confirmed: true,
        displayRoundTrip: false,
        save_comment: { source: false, destination: false },
        favorite_address: { source: false, destination: false },
        channel: domain,
        isExperimentalUnmountField: true
      }
    }
  }
  static recordToFormHandler(isOrderDuplicate) {
    return function recordToForm(record) {
      const company = _.get(record, 'service.contract.company')
      const companyuser = _.find(_.get(record, 'do.user_companies'), ['company', company])

      return {
        user: {
          gsm: !!_.get(record, 'service') ? _.get(record, 'calling_number') : _.get(record, 'do.auth.gsm'),
          calling_number: _.get(record, 'calling_number'),
          client_number: _.get(record, 'do.customerinfo.client_number'),
          first_name: _.get(record, 'do.info.first_name'),
          last_name: _.get(record, 'do.info.last_name'),
          name: _.get(record, 'do.customerinfo.name'),
          address: _.get(record, 'do.info.address'),
          comment_to_call_taker: _.get(record, 'do.customerinfo.comment_to_call_taker'),
          application: _.get(record, 'do.auth.application'),
          commercial_formula: _.get(record, 'service') ? _.get(record, 'service.contract.formula') : _.get(record, 'do.commercial_formula'),
          companyservice: _.get(record, 'service.companyservice_id'),
          company: _.get(record, 'service.contract.company'),
          companycontract: _.get(record, 'service.contract.companycontract_id'),
          companyuser: _.get(companyuser, 'companyuser_id'),
          password: _.get(record, 'service.password'),
        },
        ridemode: record?.mode?.ridemode_id,
        ridemode_duration: record?.info?.duration,
        driver_comment: _.get(record, 'driver_comment'),
        requestedAt: TimeUtils.getDetailsMomentFront(record?.requestedAt, record?.source),
        returnAt: TimeUtils.getDetailsMomentFront(record?.returnAt, record?.source),
        schedule_type: _.get(record, 'schedule_type'),
        commercial_package: _.get(record, 'commercial_package.commercial_package_id'),
        poi: _.get(record, 'source.poi.poi_id'),
        source: GeoUtils.getFormattedObject(_.get(record, 'source')),
        destination: GeoUtils.getFormattedObject(_.get(record, 'destination')),
        loads: {
          src_contact: _.get(record, 'loads[0].src_contact'),
          src_phone: _.get(record, 'loads[0].src_phone'),
          dest_contact: _.get(record, 'loads[0].dest_contact'),
          dest_phone: _.get(record, 'loads[0].dest_phone'),
        },
        info: {
          src_comment: _.get(record, 'info.src_comment'),
          dest_comment: _.get(record, 'info.dest_comment'),
        },
        reservationinfo: {
          name: _.get(record, 'reservationinfos[0].name'),
          origin: _.get(record, 'reservationinfos[0].origin'),
          arrival: _.get(record, 'reservationinfos[0].arrival'),
        },
        comment_to_call_taker: _.get(record, 'customerinfo.comment_to_call_taker'),
        load_type: _.get(record, 'commercial_package.load_type'),
        estimation_type: _.get(record, 'quotation.type'),
        manual_price: _.get(record, 'quotation.type') === 'special' ? _.get(record, 'quotation.base') : undefined,
        driver_opts: _.map(_.get(record, 'driver_opts'), 'user_option_id'),
        vehicle_opts: _.map(_.get(record, 'vehicle_opts'), 'vehicle_option_id'),
        payment_type: !!isOrderDuplicate ? undefined : _.get(record, 'payment_type'),
        customer_ref: _.get(record, 'customer_ref'),
        order_doc: _.get(record, 'order_doc'),

        load_count: _.get(record, 'passenger_count') || _.get(record, 'package_count'),
        has_luggage: _.get(record, 'has_luggage'),
        exclude_heatmap: record?.job?.exclude_heatmap,
        vehicle_count: 1,


        // BILLING SECTION
        assigned_transport: {
          info: {
            effective_handling_duration: _.get(record, 'assigned_transport.info.effective_handling_duration') || '0',
            billed_handling_duration: _.get(record, 'assigned_transport.info.billed_handling_duration') || '0'
          }
        },
        handling: _.get(record, 'job.payment.handling') || _.get(record, 'assigned_transport.payment.handling') || '0',
        base: _.get(record, 'job.payment.base') || _.get(record, 'assigned_transport.payment.base') || '0',
        driver_package_fee: _.get(record, 'job.payment.driver_package_fee') || _.get(record, 'assigned_transport.payment.driver_package_fee') || '0',
        fees: _.get(record, 'job.payment.fees') || _.get(record, 'assigned_transport.payment.fees') || '0',
        prepaid: _.get(record, 'job.payment.prepaid') || _.get(record, 'assigned_transport.payment.prepaid') || '0',
        payment_ref: _.get(record, 'payment_ref'),
        billing_status: _.get(record, 'billing_status') || undefined,
        billing_customer_comment: _.get(record, 'billing_customer_comment'),
        billing_member_comment: _.get(record, 'billing_member_comment'),
        duration_amount: _.get(record, 'assigned_transport.payment.duration_amount') || _.get(record, 'job.payment.duration_amount') || '0',
        option_amount: _.get(record, 'assigned_transport.payment.option_amount') || _.get(record, 'job.payment.option_amount') || '0',
      }
    }
  }

  static recordToStateHandler(isOrderDuplicate, domain) {
    return function recordToState(record) {
      return {
        confirmed: record?.confirmed,
        isManagingRecord: !isOrderDuplicate,
        isReadOnly: !isOrderDuplicate,
        channel: isOrderDuplicate ? domain : record?.channel,
        isOrderDuplicate,
        displayRoundTrip: !!record?.returnAt,
        isB2B: !!_.get(record, 'service'),
        guest: false,
        company_id: _.get(record, 'service.contract.company'),
        user_id: _.get(record, 'do.user_id'),
        billing: false,
        hasJobPayment: _.has(record, 'job.payment'),
        isMapOpen: false,
        call_id: _.get(record, 'info.call_id'),
        job_id: _.get(record, 'job.job_id'),
        isManagingBillingRecord: false,
        source_reservationinfo_id: _.get(record, 'source.poi.type.reservationinfo'),
        order_reservationinfo_id: _.get(record, 'reservationinfos.0.order_reservationinfo_id'),
        meeting_point: {
          translated: _.get(record, 'info.meeting_point_translated')
        },
        driver_instruction: {
          translated: _.get(record, 'info.driver_instruction_translated')
        },
        details: {
          source: _.get(record, 'source'),
          destination: _.get(record, 'destination')
        },
        isGrouped: (_.get(record, 'job.orders') || []).length >= 2,
        isDurationRideMode: record?.mode?.config?.display_duration_field,
        ridemode_info: {
          nights: record?.info?.night,
          duration: record?.info?.duration
        },
        psp: record?.transaction?.psp,
        selected_user: (!!isOrderDuplicate && !!record?.service) ? record?.do?.user_id : undefined,
        isExperimentalUnmountField: true
      }
    }
  }
  static formToRecordHandler(CODE_INTERNATIONAL, getEstimation) {
    return function formToRecord(values, extra, meta, state) {
      const load_type = _.get(values, 'load_type')
      const { billing, hasJobPayment, isManagingRecord } = state
      const reservationinfo_value = _.get(values, 'reservationinfo')
      const reservationinfo_id_state = _.get(state, 'source_reservationinfo_id')
      const estimation = getEstimation(values?.commercial_package, values?.estimation_type)

      const reservationinfos = getReservationInfo(reservationinfo_value, reservationinfo_id_state, state?.order_reservationinfo_id)

      const formatted_requestedAt = verifyData(_.has(values, 'requestedAt'), TimeUtils.getDetailsMomentBack(values?.requestedAt, state?.details?.source))

      return _.merge({
        confirmed: extra?.forceConfirmed ?? state?.confirmed,
        requestedAt: formatted_requestedAt,
        returnAt: verifyData(_.has(values, 'returnAt'), TimeUtils.getDetailsMomentBack(values?.returnAt, state?.details?.source)),
        commercial_package: !!estimation?.estimation_id ? values?.commercial_package : undefined,
        customer_ref: values?.customer_ref,
        driver_comment: values?.driver_comment,
        driver_opts: verifyData(_.has(values, 'driver_opts'), _.compact(values?.driver_opts)),
        vehicle_opts: verifyData(_.has(values, 'vehicle_opts'), _.compact(values?.vehicle_opts)),
        order_doc: values?.order_doc,
        payment_type: values?.payment_type,
        schedule_type: values?.schedule_type,
        calling_number: values?.user?.gsm,
        passenger_count: values?.passenger_count,
        package_count: values?.package_count,
        has_luggage: values?.has_luggage,
        estimation_id: estimation?.estimation_id,
        mode: values?.ridemode,
        destination: getAddressInfo(values, 'destination', state?.save_comment_destination),
        source: getAddressInfo(values, 'source', state?.save_comment_source),
        info: {
          margin: state?.margin,
          src_comment: values?.info?.src_comment,
          dest_comment: values?.info?.dest_comment,
          call_id: state?.call_id,
          meeting_point: state?.info?.meeting_point?.translated,
          meeting_point_trkey: state?.meeting_point?.trkey,
          driver_instruction: state?.info?.driver_instruction?.translated,
          driver_instruction_trkey: state?.driver_instruction?.trkey,
          duration: values?.ridemode_duration
        },
        loads: {
          src_contact: values?.loads?.src_contact,
          src_phone: verifyData(values?.loads?.src_phone, FormatUtils.parsePhoneNumber(values?.loads?.src_phone, CODE_INTERNATIONAL), undefined),
          dest_contact: values?.loads?.dest_contact,
          dest_phone: verifyData(values?.loads?.dest_phone, FormatUtils.parsePhoneNumber(values?.loads?.dest_phone, CODE_INTERNATIONAL), undefined)
        },
        handling_duration: values?.quotation?.handling_duration,
        // BILLING
        billing_status: values?.billing_status,
        billing_customer_comment: values?.billing_customer_comment,
        billing_group_comment: values?.billing_member_comment,
        payment_ref: values?.payment_ref
      },
        !!billing
          ? {
            assigned_transport: {
              passenger_count: load_type === 'user' ? values?.load_count : undefined,
              package_count: load_type === 'package' ? values?.load_count : undefined,
              info: {
                effective_handling_duration: values?.assigned_transport?.info?.effective_handling_duration,
                billed_handling_duration: values?.assigned_transport?.info?.billed_handling_duration,
              },
              payment: {
                handling: values?.handling,
                ...(!!hasJobPayment && {
                  base: verifyData(values?.quotation?.base, values?.quotation.base),
                  fees: verifyData(values?.quotation?.fees, values?.quotation.fees),
                  prepaid: verifyData(values?.quotation?.prepaid, values?.quotation.prepaid),
                  duration_amount: verifyData(values?.duration_amount, values?.duration_amount),
                  option_amount: verifyData(values?.option_amount, values?.option_amount)
                })

              }
            },
            job: {
              payment: {
                handling: values?.handling,
                ...(!!hasJobPayment && {
                  base: verifyData(values?.base, values?.base),
                  fees: verifyData(values?.fees, values?.fees),
                  prepaid: verifyData(values?.prepaid, values?.prepaid),
                  duration_amount: verifyData(values?.duration_amount, values?.duration_amount),
                  option_amount: verifyData(values?.option_amount, values?.option_amount)
                })
              }
            }
          }
          : {},
        { reservationinfos },
        !isManagingRecord ? {
          do: getUser(values, state),
          service: values?.user?.companyservice,
        } : {},
      )
    }
  }
  static formToOptionsHandler() {
    return function formToOptions(values, extra, meta, state) {
      return ({
        repeat: _.get(values, 'vehicle_count'),
        single: (_.get(values, 'vehicle_count') || 1) === 1,
        override_multiple_order_check: true,
        load_type: _.get(values, 'load_type'),
        load_count: _.get(values, 'load_count'),
        manual_price: _.get(values, 'manual_price'),
        estimation_type: values?.estimation_type,
        application: _.get(values, 'user.application'),
        disable_traffic_validate: true,
        exclude_heatmap: values?.exclude_heatmap
      })
    }
  }

  static onSubmitSuccessHandler() {
    return function onSubmitSuccess(result, values, extra, meta, state) {
      console.log("🚀 ~ file: callbacks.js ~ line 166 ~ Callbacks ~ onSubmitSuccess ~ result", result)
    }
  }
  static onSubmitFailedHandler() {
    return function onSubmitFailed(err, values, extra, meta, state) {
      console.log("🚀 ~ file: callbacks.js ~ line 171 ~ Callbacks ~ onSubmitFailed ~ err", err)
    }
  }

  static getFailedNotificationHandler() {
    return function getFailedNotification(err, values, extra, meta, state) {
      const code = err.getCode()
      const context = err.getContext()
      const message = (err instanceof errors.MismatchError && context?.data?.message) || I18n.t('order.failed')
      if (code === 'UNAUTHORIZED_SERVICE_SCHEDULE') {
        return [
          I18n.t('unauthorized_schedule.popup.description', {
            order_type: I18n.t(`order.${values?.schedule_type}.longLabel`, { count: 1 }),
            requestedAt: moment(values?.requestedAt).format(values?.schedule_type === 'immediate' ? 'HH[h]mm[min]' : `[${I18n.t('prefix.the')}] DD/MM/YYYY HH[h]mm[min]`)
          })
          , { variant: 'error' }
        ]
      }
      else if (code === 'LIMIT_EXCEEDED')
        return [I18n.t(`order.limit_error.${context.reason}`), { variant: 'error' }]
      else
        return [message, { variant: 'error' }]
    }
  }
  static handleValidateNotificationHandler(openNotification, closeNotification, setFormValues) {
    return async function handleValidateNotification(data) {
      _.map(data, error => {
        const message = error?.message
        const argument = error?.context?.argument
        const suggestion = error?.context?.suggestion
        const type = error?.type
        !!message && openNotification(message, { variant: type }, {
          preventDuplicate: true,
          action: !!suggestion
            ? (key) => {
              return (
                <Button
                  onClick={() => {
                    setFormValues([{ field: argument, value: suggestion }])
                    closeNotification(key)
                  }}
                  sx={{ color: 'white', textDecoration: 'underline' }}
                  variant='text'>
                  {I18n.t('action.apply')}
                </Button>
              )
            }
            : undefined
        })

      })
    }
  }

  static handleWaitingOrangeHandler() {
    return async function handleWaitingOrange() {
      return OrangeContact.execute('GET', 'clickToCall', {
        queueName: 'WSFLEET',
        transfer: true,
        autoswitch: true,
        autowrapup: true
      })
    }
  }
  static handleOrangeCallHandler() {
    return async function handleOrangeCall(phoneNumber) {
      return OrangeContact.execute('GET', 'clickToCall', { phoneNumber })
    }
  }
  static handleCallHandler(EXTERNAL_PHONE_SERVICE, orangeCall) {
    return async function call(number) {
      if (!number) return
      if (EXTERNAL_PHONE_SERVICE === 'aws') {
        const endpoint = connect.Endpoint.byPhoneNumber(number)
        const agent = new connect.Agent()
        const contacts = agent.getContacts()
        if (_.isEmpty(contacts))
          agent.connect(endpoint)
        else if (contacts.length === 1)
          contacts[0].addConnection(endpoint)
      }
      else if (EXTERNAL_PHONE_SERVICE === 'orange') {
        const phoneNumber = encodeURIComponent(number)
        await orangeCall(phoneNumber)
      }
    }
  }
  static handleClickCallCenterHandler(reset, setIsWaiting) {
    return function handleClickCallCenter() {
      reset()
      setIsWaiting(true)
    }
  }
  static handleClickMapHandler(isMapOpen, setFormState) {
    return function handleClickMap() {
      if (!isMapOpen) setTimeout(() => { window.scrollTo(0, document.body.scrollHeight) }, 500)
      setFormState({ isMapOpen: !isMapOpen })

    }
  }
  static handleCancelHandler(cancel, order, openNotification, handleOpenCancelConfirmationDialog, isGrouped) {
    return async function handleCancel(force, reason, cancelation_comment) {
      const { confirmed, order_id } = order

      if ((_.isBoolean(force) && !!force) || !confirmed) {
        cancel({ order_id, reason, cancelation_comment })
        openNotification(I18n.t('event.order_canceled'), { variant: 'success' })
      }
      else if (!force && !!confirmed || isGrouped) {
        handleOpenCancelConfirmationDialog(isGrouped)
      }
    }
  }

  static handleNewOrderHandler(navigate) {
    return function handleNewOrder() {
      navigate('/order/create')
    }
  }


  static handleInvoiceDownloadHanlder(downloadInvoice, openNotification) {
    return async function handleInvoiceDownload() {
      try {
        await downloadInvoice()
      }
      catch (err) {
        console.warn("Failed to download file", err)
        openNotification('Facture non disponible', { variant: 'error' })
      }
    }
  }

  static isDownloadAvailableStatusHandler(isDownloadAvailable, setFormState) {
    return async function isDownloadAvailableStatus(order_id) {
      try {
        const response = await isDownloadAvailable({ order_id, type: 'invoice' })
        setFormState({ displayDownloadBillingButton: !!response?.status })

      } catch (err) {
        console.error('failed to get download available status', err)
        setFormState({ displayDownloadBillingButton: false })
      }
    }
  }
  static getModifyFieldsHandler(order, isOrderDuplicate) {
    return function getModifyFields() {
      const canModifyAllFields = !!isOrderDuplicate
        || _.includes(['created', 'standby'], order?.status)
        || (_.includes(['attributed'], order?.status) && moment(order?.requestedAt).isAfter(moment()))

      return canModifyAllFields
        ? []
        : ['customer_ref', 'order_doc', 'info.src_comment', 'info.dest_comment', 'driver_comment']
    }
  }
  static handleOrderCopyHandler(record_id) {
    return function handleOrderCopy() {
      window.open(`/order/create?order_id=${record_id}`, '_blank')
    }
  }
  static beforeSubmitHanbdler(openNotification) {
    return function beforeSubmit(values, extra, meta, state) {
      if (!!state?.isB2B && !state?.hasCompanyAccess && !!values?.companyservice_id) {
        openNotification(I18n.t('password.incorrect'), { variant: 'error' })
        return false
      }
      return true
    }
  }
}

export default Callbacks
